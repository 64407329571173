@tailwind base;
@tailwind components;
@tailwind utilities;

.logo-fix {
  margin-bottom: 20px; 
}
.cell-animation {
  animation: revealCharCell linear;
  animation-duration: 0.15s;
}

@keyframes revealCharCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.fade-in {
	animation: fade-me-in 1s linear;
}

@keyframes fade-me-in {
  0% {
	opacity: 0; 
  }
  100% {
	opacity: 1;
  }
}

.beam-me-in:before {
	animation: teleport 1s linear; 
	content: '';
	background: #2b53a7;
	border-radius: 25%; 
	display: block; 
        float: left;
	height: 15px;
	width: 0px;
	opacity: .5;
	position: relative;
	z-index: 2;
	top: -20px;
	right: 15px;
	transform: scale(1);
}

@keyframes teleport {
  0% {
        width: 10px;
        box-shadow:
		rgba(43, 83, 167, 0) -5px 2px,
		rgba(43, 83, 167, 0) 0px 10px,
		#2b53a7 16px 27px,
		#2b53a7 27px 15px,
		#2b53a7 35px 40px,
		#2b53a7 40px 27px,
		#2b53a7 45px 12px,
		#2b53a7 53px 5px;
     }

  25% {
        box-shadow:
		#2b53a7 2px 12px,
		#2b53a7 7px 20px,
		#2b53a7 16px 45px,
		#2b53a7 27px 32px,
		rgba(43, 83, 167, 0) 35px 56px,
		rgba(43, 83, 167, 0) 40px 56px,
		#2b53a7 45px 23px,
		#2b53a7 53px 15px;
     }

  50% {
        box-shadow:
		#2b53a7 2px 25px,
		#2b53a7 7px 35px,
		rgba(43, 83, 167, 0) 16px 56px,
		#2b53a7 27px 40px,
		#2b53a7 35px 2px,
		#2b53a7 40px 5px,
		#2b53a7 45px 33px,
		#2b53a7 53px 45px;
     }

  75% {
        box-shadow:
		#2b53a7 2px 42px,
		#2b53a7 7px 55px,
		#2b53a7 16px 12px,
		rgba(43, 83, 167, 0) 27px 50px,
		#2b53a7 35px 17px,
		#2b53a7 40px 14px,
		rgba(43, 83, 167, 0) 45px 60px,
		rgba(43, 83, 167, 0) 53px 56px;
     }

  100% {
        box-shadow:
		rgba(43, 83, 167, 0) 2px 56px,
		rgba(43, 83, 167, 0) 7px 56px,
		#2b53a7 16px 28px,
		#2b53a7 27px 3px,
		#2b53a7 35px 33px,
		#2b53a7 40px 40px,
		#2b53a7 45px 3px,
		#2b53a7 53px 15px;
     }
}

.shadowed {
  text-shadow: 1px 1px 1px #000000;
}
