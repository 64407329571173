html.dark {
  background-color: rgb(15, 23, 42);
  background-image: url("stars6.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

/* libre-franklin-regular - latin */
@font-face {
  font-family: 'Libre Franklin';
  font-style: normal;
  font-weight: 400;
  src: url('libre-franklin-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('libre-franklin-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('libre-franklin-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('libre-franklin-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('libre-franklin-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('libre-franklin-v12-latin-regular.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}
/* libre-franklin-200italic - latin */
@font-face {
  font-family: 'Libre Franklin';
  font-style: italic;
  font-weight: 200;
  src: url('libre-franklin-v12-latin-200italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('libre-franklin-v12-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('libre-franklin-v12-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('libre-franklin-v12-latin-200italic.woff') format('woff'), /* Modern Browsers */
       url('libre-franklin-v12-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('libre-franklin-v12-latin-200italic.svg#LibreFranklin') format('svg'); /* Legacy iOS */
}

textarea {
  font-family: 'Libre Franklin';
  font-style: italic;
}

#winnerText {
  font-family: 'Libre Franklin';
  font-style: italic;

}

#sharetoon {
  height: 22rem;
}
